import ky from 'ky'
import router from '@/router'
import store from '@/store'
import { SERVER_URL } from '@/config.js'

// request util to use in actions
const request = async (query, variables = {}, token = 0) => {
  try {
    const response = await ky(
      SERVER_URL,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ query, variables }),
        hooks: {
          afterResponse: [
            async (_input, _options, response) => {
              const res = await response.json()
              if (res.data && res.data.currentOperator && res.data.currentOperator.blocked) {
                store.dispatch('logout')
                router.push('/')
              } else if (res.errors && res.errors[0].message === 'TokenExpired') {
                router.replace({ name: 'session-expired' })
              } else if (res.error) {
                store.commit('SHOW_SNACKBAR', { message: res.error.errors[0].message })
              } else if (res.errors) {
                store.commit('SHOW_SNACKBAR', { message: res.errors[0].message })
              }
            }
          ]
        }
      }).json()

    return response
  } catch (err) {
    if (err.name === 'TimeoutError') {
      store.commit('SHOW_SNACKBAR', { message: 'Please check your internet connection and try again.' })
    }
  }
}

export default request
