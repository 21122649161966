export default function initialState () {
  return {
    version: 17,
    snackbar: {
      visible: false,
      timeout: 6000,
      message: null
    },
    ecosystem: null,
    // session info
    token: null,
    operator: {
      id: null,
      role: null,
      name: null,
      phoneNumber: null,
      avatar: null,
      ecosystems: null
    },
    stations: null,
    currentStation: null,
    ongoingRides: null
  }
}
