import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

// define multiple guards helper
const isUndefined = (value) => value === undefined
const evaluateGuards = (guards, to, from, next) => {
  const guardsLeft = guards.slice(0)
  const nextGuard = guardsLeft.shift()

  if (isUndefined(nextGuard)) {
    next()
    return
  }

  nextGuard(to, from, (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, to, from, next)
      return
    }

    next(nextArg)
  })
}
const multipleGuards = (guards) => {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards')
  }

  return (to, from, next) => {
    return evaluateGuards(guards, to, from, next)
  }
}

// atomic guard rules
const disallowIfLogin = (to, from, next) => {
  if (store.getters.isloggedIn) {
    next({ path: '/app' })
  } else {
    next()
  }
}
const requirePhoneNumber = (to, from, next) => {
  if (!store.getters.operatorPhoneNumber) {
    next({ path: '/' })
  } else {
    next()
  }
}
const requireLogin = (to, from, next) => {
  if (!store.getters.isloggedIn) {
    next({ path: '/' })
  } else {
    next()
  }
}

// create new router
export default new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'phone',
      component: () => import('@/views/Phone.vue'),
      beforeEnter: disallowIfLogin
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      beforeEnter: multipleGuards([requirePhoneNumber, disallowIfLogin])
    },
    {
      path: '/ecosystem',
      name: 'ecosystem',
      component: () => import('@/views/Ecosystem.vue'),
      beforeEnter: multipleGuards([requireLogin])
    },
    {
      path: '/app',
      component: () => import('@/views/MainApp.vue'),
      beforeEnter: multipleGuards([requireLogin]),
      children: [
        {
          path: '',
          name: 'stations',
          component: () => import('@/views/Stations.vue'),
          meta: { title: 'Stations' }
        },
        {
          path: 'station/:id',
          name: 'station',
          component: () => import('@/views/Station.vue'),
          meta: { title: 'Station info' }
        },
        {
          path: 'ongoing-rides',
          name: 'ongoing-rides',
          component: () => import('@/views/OngoingRides.vue'),
          meta: { title: 'Ongoing rides' }
        },
        {
          path: 'tasks',
          component: () => import('@/views/TasksMain.vue'),
          children: [
            {
              path: 'open',
              name: 'tasks-open',
              component: () => import('@/views/TasksOpen.vue'),
              meta: { title: 'Tasks' }
            },
            {
              path: 'completed',
              name: 'tasks-completed',
              component: () => import('@/views/TasksCompleted.vue'),
              meta: { title: 'Tasks' }
            },
            {
              path: 'new',
              name: 'tasks-new',
              component: () => import('@/views/TasksNew.vue'),
              meta: { title: 'Tasks' }
            }
          ]
        },
        {
          path: 'update-task',
          name: 'update-task',
          component: () => import('@/views/UpdateTask.vue'),
          meta: { title: 'Task' }
        },
        {
          path: 'task-details',
          name: 'task-details',
          component: () => import('@/views/TaskDetails.vue'),
          meta: { title: 'Task' }
        },
        {
          path: 'attendance',
          component: () => import('@/views/AttendanceMain.vue'),
          children: [
            {
              path: 'new',
              name: 'attendance-new',
              component: () => import('@/views/AttendanceNew.vue'),
              meta: { title: 'Attendance' }
            },
            {
              path: 'view',
              name: 'attendance-view',
              component: () => import('@/views/AttendanceView.vue'),
              meta: { title: 'Attendance' }
            }
          ]
        },
        {
          path: 'cycles',
          name: 'cycles',
          component: () => import('@/views/Cycles.vue'),
          meta: { title: 'Cycles' }
        },
        {
          path: 'spare-parts',
          name: 'spare-parts',
          component: () => import('@/views/SpareParts.vue'),
          meta: { title: 'Spare parts' }
        },
        {
          path: 'spare-parts/consumption-form',
          name: 'spare-parts-consumption-form',
          component: () => import('@/views/SparePartsConsumptionForm.vue'),
          meta: { title: 'Spare Parts - Use' }
        },
        {
          path: 'spare-parts/view-items',
          name: 'spare-parts-view-items',
          component: () => import('@/views/SparePartsViewItems.vue'),
          meta: { title: 'Spare Parts - View' }
        },
        {
          path: 'spare-parts/material-issue',
          name: 'spare-parts-material-issue',
          component: () => import('@/views/SparePartsMaterialIssue.vue'),
          meta: { title: 'Material Issue - Store' }
        },
        {
          path: 'training',
          name: 'training',
          component: () => import('@/views/Training.vue'),
          meta: { title: 'Training' }
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import('@/views/Profile.vue'),
          meta: { title: 'Profile' }
        },
        {
          path: 'unlock',
          name: 'unlock',
          component: () => import('@/views/Unlock.vue'),
          meta: { title: 'Unlock' }
        },
        {
          path: 'update-avatar',
          name: 'update-avatar',
          component: () => import('@/views/UpdateAvatar.vue'),
          meta: { title: 'Update avatar' }
        },
        {
          path: '/session-expired',
          name: 'session-expired',
          component: () => import('@/views/SessionExpired.vue'),
          meta: { title: 'Session expired' }
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
