import request from './utils/request.js'
import {
  CHECK_OPERATOR_QUERY,
  LOGIN_OTP_MUTATION,
  SEND_OTP_SMS_MUTATION,
  RESEND_OTP_SMS_MUTATION,
  UPDATE_ACTIVE_ECOSYSTEM_MUTATION,
  STATIONS_QUERY,
  GET_STATION_QUERY,
  TRIGGER_DOCK_MUTATION,
  RESTART_DOCK_MUTATION,
  UPDATE_DOCK_STATUS_MUTATION,
  ONGOING_RIDES_QUERY,
  TASKS_QUERY,
  TASK_ISSUES_QUERY,
  TASK_QUERY,
  TASK_PHOTO_URL_MUTATION,
  UPDATE_TASK_MUTATION,
  CREATE_TASK_MUTATION,
  OPERATOR_LOGS_QUERY,
  OPERATOR_LOG_PHOTO_URL_MUTATION,
  CREATE_OPERATOR_LOG_MUTATION,
  BIKES_QUERY,
  BIKE_STATS_QUERY,
  UPDATE_BIKE_STATE_MUTATION,
  GET_BIKE_QUERY,
  DOCK_QUERY,
  OPERATOR_PROFILE_QUERY,
  OPERATOR_AVATAR_URL_MUTATION,
  UPDATE_OPERATOR_AVATAR_MUTATION
} from './graphql.js'
import * as Sentry from '@sentry/vue'

export default {
  async checkOperator ({ commit, state, dispatch }, payload) {
    const response = await request(CHECK_OPERATOR_QUERY, payload)

    if (response && response.data && response.data.checkOperator) {
      if (!response.data.checkOperator.exists) {
        commit('SHOW_SNACKBAR', { message: 'An operator with this phone number does not exist.' })
      } else if (response.data.checkOperator.blocked) {
        commit('SHOW_SNACKBAR', { message: 'Your account has been blocked. Please contact support.' })
      } else {
        commit('SET_OPERATOR_PHONE_NUMBER', payload.phoneNumber)
        return response.data.checkOperator
      }
    }
  },
  async loginOtp ({ commit, state }, payload) {
    const response = await request(LOGIN_OTP_MUTATION, { input: payload })

    if (response && response.data && response.data.loginOperatorOtp) {
      commit('SET_OPERATOR_SESSION', response.data.loginOperatorOtp)
      Sentry.setUser({ id: response.data.loginOperatorOtp.operator.id, name: response.data.loginOperatorOtp.operator.name, phoneNumber: response.data.loginOperatorOtp.operator.phoneNumber })
      return response.data.loginOperatorOtp
    }
  },
  async sendOtpSms ({ commit, state, dispatch }, payload) {
    const response = await request(SEND_OTP_SMS_MUTATION, { input: payload })

    if (response && response.data && response.data.sendOtpSms) {
      commit('SHOW_SNACKBAR', { message: 'An OTP has been sent to your phone.' })
      return response.data.sendOtpSms
    }
  },
  async resendOtpSms ({ commit, state }, payload) {
    const response = await request(RESEND_OTP_SMS_MUTATION, { input: payload })

    if (response && response.data && response.data.resendOtpSms && response.data.resendOtpSms.type === 'success') {
      commit('SHOW_SNACKBAR', { message: 'An OTP has been sent to your phone.' })
      return response.data.resendOtpSms
    }
  },
  async updateActiveEcosystem ({ commit, state }, payload) {
    const response = await request(UPDATE_ACTIVE_ECOSYSTEM_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateActiveOperatorEcosystem) {
      commit('SET_OPERATOR_SESSION', { operator: response.data.updateActiveOperatorEcosystem })
      return response.data.updateActiveOperatorEcosystem
    }
  },
  async getStations ({ commit, state }, payload) {
    const response = await request(STATIONS_QUERY, payload, state.token)

    if (response && response.data && response.data.stations) {
      const stations = response.data.stations.edges.map((edge) => edge.node)
      return stations
    }
  },
  async getStation ({ commit, state, dispatch }, payload) {
    const response = await request(GET_STATION_QUERY, payload, state.token)

    if (response && response.data && response.data.stations) {
      const station = response.data.stations.edges[0].node
      return station
    }
  },
  async triggerDock ({ commit, state, dispatch }, payload) {
    const response = await request(TRIGGER_DOCK_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.triggerDock) {
      return response.data.triggerDock
    }
  },
  async updateDockStatus ({ commit, state, dispatch }, payload) {
    const response = await request(UPDATE_DOCK_STATUS_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateDockStatus) {
      return response.data.updateDockStatus
    }
  },
  async restartDock ({ commit, state, dispatch }, payload) {
    const response = await request(RESTART_DOCK_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.restartDock) {
      return response.data.restartDock
    }
  },
  async getOngoingRides ({ commit, state, dispatch }, payload) {
    const response = await request(ONGOING_RIDES_QUERY, payload, state.token)

    if (response && response.data && response.data.ongoingRides) {
      const ongoingRides = response.data.ongoingRides.edges.map((edge) => edge.node)
      return ongoingRides
    }
  },
  async getTasks ({ commit, state, dispatch }, payload) {
    const response = await request(TASKS_QUERY, payload, state.token)

    if (response && response.data && response.data.currentOperator && response.data.currentOperator.tasks) {
      return response.data.currentOperator.tasks
    }
  },
  async getTaskIssues ({ commit, state }, payload) {
    const response = await request(TASK_ISSUES_QUERY, payload, state.token)

    if (response && response.data && response.data.taskIssues) {
      return response.data.taskIssues
    }
  },
  async getTask ({ commit, state, dispatch }, payload) {
    const response = await request(TASK_QUERY, payload, state.token)

    if (response && response.data && response.data.task) {
      return response.data.task
    }
  },
  async getTaskPhotoUrl ({ commit, state, dispatch }, payload) {
    const response = await request(TASK_PHOTO_URL_MUTATION, {}, state.token)

    if (response && response.data && response.data.createTaskPhotoUrl) {
      return response.data.createTaskPhotoUrl
    }
  },
  async updateTask ({ commit, state, dispatch }, payload) {
    const response = await request(UPDATE_TASK_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateTask) {
      return response.data.updateTask
    }
  },
  async createTask ({ commit, state, dispatch }, payload) {
    const response = await request(CREATE_TASK_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createTask) {
      return response.data.createTask
    }
  },
  async getOperatorLogs ({ commit, state, dispatch }, payload) {
    const response = await request(OPERATOR_LOGS_QUERY, payload, state.token)

    if (response && response.data && response.data.operatorLogs) {
      return response.data.operatorLogs
    }
  },
  async getOperatorLogPhotoUrl ({ commit, state, dispatch }, payload) {
    const response = await request(OPERATOR_LOG_PHOTO_URL_MUTATION, {}, state.token)

    if (response && response.data && response.data.createOperatorLogPhotoUrl) {
      return response.data.createOperatorLogPhotoUrl
    }
  },
  async createOperatorLog ({ commit, state, dispatch }, payload) {
    const response = await request(CREATE_OPERATOR_LOG_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.createOperatorLog) {
      return response.data.createOperatorLog
    }
  },
  async getBikes ({ commit, state, dispatch }, payload) {
    const response = await request(BIKES_QUERY, payload, state.token)

    if (response && response.data && response.data.bikes) {
      const bikes = response.data.bikes.edges.map((edge) => edge.node)
      return bikes
    }
  },
  async getBikeStats ({ commit, state, dispatch }, payload) {
    const response = await request(BIKE_STATS_QUERY, payload, state.token)

    if (response && response.data && response.data.bikeStats) {
      return response.data.bikeStats
    }
  },
  async updateBikeState ({ commit, state, dispatch }, payload) {
    const response = await request(UPDATE_BIKE_STATE_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateBikeState) {
      return response.data.updateBikeState
    }
  },
  async getBike ({ commit, state, dispatch }, payload) {
    const response = await request(GET_BIKE_QUERY, payload, state.token)

    if (response && response.data) {
      if (response.data.bike) {
        return response.data.bike
      } else {
        commit('SHOW_SNACKBAR', { message: 'No cycle was found.' })
      }
    }
  },
  async getDock ({ commit, state, dispatch }, payload) {
    const response = await request(DOCK_QUERY, payload, state.token)

    if (response && response.data && response.data.dock) {
      return response.data.dock
    }
  },
  async getProfile ({ commit, state }, payload) {
    const response = await request(OPERATOR_PROFILE_QUERY, payload, state.token)

    if (response && response.data && response.data.currentOperator) {
      commit('SET_OPERATOR_SESSION', { operator: response.data.currentOperator })
      return response.data.currentOperator
    }
  },
  async getOperatorAvatarUrl ({ commit, state, dispatch }, payload) {
    const response = await request(OPERATOR_AVATAR_URL_MUTATION, {}, state.token)

    if (response && response.data && response.data.createOperatorAvatarUrl) {
      return response.data.createOperatorAvatarUrl
    }
  },
  async updateOperatorAvatar ({ commit, state, dispatch }, payload) {
    const response = await request(UPDATE_OPERATOR_AVATAR_MUTATION, { input: payload }, state.token)

    if (response && response.data && response.data.updateOperatorAvatar) {
      return response.data.updateOperatorAvatar
    }
  },
  logout ({ commit, state }) {
    commit('CLEAR_STATE')
    Sentry.setUser(null)
  }
}
