import initialState from './state.js'

export default {
  /* general mutations */
  SHOW_SNACKBAR (state, data) {
    state.snackbar = {
      visible: true,
      timeout: data.timeout || 6000,
      message: data.message
    }
  },
  CLOSE_SNACKBAR (state) {
    state.snackbar.visible = false
  },

  /* pre-login mutations */

  SET_OPERATOR_PHONE_NUMBER (state, data) {
    state.operator.phoneNumber = data
  },

  /* login mutations */
  SET_OPERATOR_SESSION (state, data) {
    // set token
    if (data.token) state.token = data.token
    // set operator data
    state.operator = { ...state.operator, ...data.operator }
    // set active ecosystem
    const activeEcosystem = data.operator.ecosystems.find((obj) => obj.active === true)
    state.ecosystem = activeEcosystem.ecosystem
  },

  /* logout mutations */

  CLEAR_STATE (state) {
    Object.assign(state, initialState())
  }
}
